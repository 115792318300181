import React from 'react'
import { useTranslation } from 'react-i18next'

import ProvinceHeader from 'components/ProvinceHeader'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import MetaTags from 'components/MetaTags'
import ChangesList from 'components/ChangesList'

const BritishColumbia = () => {
  const { t } = useTranslation()

  return (
    <>
      <MetaTags title={`${t('provinces.bc')} | ${t('title')}`} />
      <ProvinceHeader name={t('provinces.bc')} slug="bc" />
      <Section>
        <Wrapper>
          <ChangesList
            name={t('provinces.bc')}
            changes={t('changes.bc', { returnObjects: true })}
          />
        </Wrapper>
      </Section>
    </>
  )
}

export default BritishColumbia
